.wizard.wizard-2 {
  display: flex;
}
.wizard.wizard-2 .wizard-nav {
  padding: 0;
  flex: 0 0 400px;
  width: 400px;
  max-width: 100%;
}
.wizard.wizard-2 .wizard-nav .wizard-steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step {
  padding: 0.75rem 1.5rem;
  position: relative;
  border-radius: 0.5rem;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
  display: flex;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-icon {
  font-size: 2.5rem;
  margin-right: 1.1rem;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-icon i {
  color: #B5B5C3;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-icon .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-icon .svg-icon svg:hover g [fill] {
  transition: fill 0.3s ease;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
  color: #181C32;
  font-weight: 500;
  font-size: 1.1rem;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-desc {
  color: #7E8299;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
  color: #7E8299;
  font-size: 1.25rem;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-arrow.last {
  display: none;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] {
  background-color: #F3F6F9;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon i {
  color: #3699FF !important;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #3699FF !important;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .svg-icon svg:hover g [fill] {
  transition: fill 0.3s ease;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current]:after {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  content: " ";
  height: 0;
  width: 0;
  border: solid transparent;
  position: absolute;
  border-left-color: #F3F6F9;
  border-width: 1rem;
}
.wizard.wizard-2 .wizard-body {
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .wizard.wizard-2 .wizard-nav {
    flex: 0 0 325px;
    width: 325px;
  }
}
@media (max-width: 991.98px) {
  .wizard.wizard-2 {
    display: block;
  }
  .wizard.wizard-2 .wizard-nav {
    width: 100%;
    border-bottom: 1px solid #EBEDF3;
  }
  .wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current]:after {
    content: none;
  }
  .wizard.wizard-2 .wizard-body {
    width: 100%;
  }
}

.wrapper{
  margin: 0 auto;
}

#signature-pad{
  border: 2px solid #3699ff !important;
  border-radius: 8px !important;
}
#signature-pad:hover{
  background: #3699ff82;
}
.clear{
  background: #3699ff;
  width: 150px;
  text-align: center;
  border-radius: 0 0 9px 9px;
  padding: 4px;
  border: none;
  left: calc(50% - 75px);
  color: #fff;
  font-size: 1.1em;
  text-transform: uppercase;
}

#clear:hover{
  background: #3699ff82;
}


label[for='edl_form_cleAppart'],label[for='edl_form_clePortail'], label[for='edl_form_cleBAL']{
  margin-left: 50px;
  top: -4px;
  position: relative;
}

label[for='edl_form_cleAppart']:before ,label[for='edl_form_clePortail']:before, label[for='edl_form_cleBAL']:before{
  content: "";
  background-image: url("../../Fichiers/cles.png");
  background-position: 50% 50%;
  background-size: cover;
  height: 32px;
  width: 32px;
  position: absolute;
  left: -45px;
  top: -8px;
}

/* supresion des fleches sur les selects */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

/*# sourceMappingURL=wizard-2.css.map*/